import {Paragraph} from "../paragraphs/paragraph";
import classNames from "classnames";
import {CSSProperties, Fragment} from "react";
import {SectionSection} from "./section--section";

export const backgroundStyles = {
  'white': 'bg-white',
  'blue': 'bg-primary',
  'primary': 'bg-primary text-white',
  'dotted': 'bg-dotted',
  'purple': 'bg-purple-100',
  'green': 'bg-green-100',
  'teal': 'bg-teal-100',
  'teal_gradient': 'bg-gradient-to-r from-teal-200 to-blue-100',
  'blue_gradient': 'bg-gradient-to-r from-blue-200 to-blue-100',
  'purple_gradient': 'bg-gradient-to-r from-purple-200 to-pink-100',
};

export const backgroundTextColor = {
  'blue': 'text-white',
  'primary': 'text-white',
};

/**
 * Renders all the sections and their regions.
 *
 * @param sections
 *   The sections.
 * @param sectionMap
 *   The section map.
 * @param noPad
 *   Don't add left or right padding.
 * @constructor
 */
export function RenderSections({sections, sectionMap, noPad = false}) {
  return (
    <>
      {sections?.length ? sections.map((section, idx) => {
        if (section?.behavior_settings?.layout_paragraphs?.parent_uuid) return <Fragment key={`section--${section.id}`}></Fragment>;
        return (
          <Fragment key={`section--${section.id}`}>
            {section?.type === 'paragraph--section' && <SectionSection
                first={idx === 0}
                section={section}
                noPad={noPad}
                sectionMap={sectionMap}
                nestedSections={sections.filter(nested => section?.id === nested?.behavior_settings?.layout_paragraphs?.parent_uuid)}
            />}
          </Fragment>
        )
      }) : <></>}
    </>
  )
}

/**
 * Populates any nested sections, if present.
 *
 * @param sectionMap
 *   The original section map.
 * @param section
 *   The current section.
 * @param region
 *   The region to for the section.
 * @param nestedSections
 *   The nested sections belonging to the section.
 * @constructor
 */
function NestedSections({sectionMap, section, region, nestedSections}) {
  return (
    <>
      {nestedSections?.length ?
        nestedSections.map((nestedSection, idx) => (
          <Fragment key={`section--${nestedSection?.id}`}>
            {section?.type === 'paragraph--section' && ["content", region].includes(nestedSection?.behavior_settings?.layout_paragraphs?.region) && <SectionSection
                first={idx === 0}
                section={nestedSection}
                sectionMap={sectionMap}
                nestedSections={nestedSections.filter(nested => nestedSection?.id === nested?.behavior_settings?.layout_paragraphs?.parent_uuid)}
            />}
          </Fragment>
        ))
        : <></>}
    </>
  )
}


/**
 * Displays the section regions with their paragraphs or nested sections.
 *
 * @param sectionMap
 *   The original section map.
 * @param section
 *   The current section.
 * @param nestedSections
 *   The nested sections belonging to the section.
 * @param props
 *   Any additional properties.
 * @constructor
 */
export function SectionRegions ({sectionMap, section, nestedSections,  ...props}) {
  const sections = sectionMap?.[section?.id] ?? {};
  const regions = sections ? Object.keys(sections).length : 0;
  const textColor = props?.textColor;
  const width = section?.behavior_settings?.layout_paragraphs?.config?.column_widths ?? "";

  const style: CSSProperties = {};
  if (section?.field_section_gap) style.gap = `${section.field_section_gap}rem`;
  if (section?.field_section_wrap) style.flexWrap = "wrap";
  if (section?.field_section_padding) {
    style.paddingLeft = `${section?.field_section_padding}rem`;
    style.paddingRight = `${section?.field_section_padding}rem`;
  }
  if (width === "75-25") {
    style.gridTemplateColumns = "75% 25%";
  }
  else if (width === "25-75") {
    style.gridTemplateColumns = "25% 75%";
  }
  else if (width === "33-67") {
    style.gridTemplateColumns = "33% 67%";
  }
  else if (width === "67-33") {
    style.gridTemplateColumns = "67% 33%";
  }
  else if (width === "50-50") {
    style.gridTemplateColumns = "50% 50%";
  }
  else if (width === "33-34-33") {
    style.gridTemplateColumns = "33% 34% 33%";
  }
  else if (width === "25-25-50") {
    style.gridTemplateColumns = "25% 25% 50%";
  }
  else if (width === "50-25-25") {
    style.gridTemplateColumns = "50% 25% 25%";
  }
  else if (width === "25-50-25") {
    style.gridTemplateColumns = "25% 50% 25%";
  }

  return (
    <div className={classNames({
      "layout-region container mx-auto px-6 2xl:px-0": section?.field_section_container,
      "!px-0": props?.noPad,
    })}>
      {regions === 1 && (
        <div className={`flex flex-col one-col w-full`} style={style}>
          {sections.first.map(paragraph => (
            <Paragraph paragraph={paragraph} key={paragraph.id} textColor={textColor}/>
          ))}
          {nestedSections?.length ? <NestedSections sectionMap={sectionMap} section={section} region={"first"} nestedSections={nestedSections} /> : <></>}
        </div>
      )}
      {regions === 2 && (
        <div className={`grid max-md:!grid-cols-1 md:grid-cols-2`} style={style}>
          <div className="w-full">
            {sections.first.map(paragraph => (
              <Paragraph paragraph={paragraph} key={paragraph.id} textColor={textColor}/>
            ))}
            {nestedSections?.length ? <NestedSections sectionMap={sectionMap} section={section} region={"first"} nestedSections={nestedSections} /> : <></>}
          </div>
          <div className="w-full">
            {sections.second.map(paragraph => (
              <Paragraph paragraph={paragraph} key={paragraph.id}/>
            ))}
            {nestedSections?.length ? <NestedSections sectionMap={sectionMap} section={section} region={"second"} nestedSections={nestedSections} /> : <></>}
          </div>
        </div>
      )}
      {regions === 3 && (
        <div className={`grid max-md:!grid-cols-1 lg:grid-cols-3`} style={style}>
          <div className="w-full">
            {sections.first.map(paragraph => (
              <Paragraph paragraph={paragraph} key={paragraph.id} textColor={textColor}/>
            ))}
            {nestedSections?.length ? <NestedSections sectionMap={sectionMap} section={section} region={"first"} nestedSections={nestedSections} /> : <></>}
          </div>
          <div className="w-full">
            {sections.second.map(paragraph => (
              <Paragraph paragraph={paragraph} key={paragraph.id} textColor={textColor}/>
            ))}
            {nestedSections?.length ? <NestedSections sectionMap={sectionMap} section={section} region={"second"} nestedSections={nestedSections} /> : <></>}
          </div>
          <div className="w-full">
            {sections.third.map(paragraph => (
              <Paragraph paragraph={paragraph} key={paragraph.id} textColor={textColor}/>
            ))}
            {nestedSections?.length ? <NestedSections sectionMap={sectionMap} section={section} region={"third"} nestedSections={nestedSections} /> : <></>}
          </div>
        </div>
      )}
      {regions === 4 && (
        <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4`} style={style}>
          <div className="w-full">
            {sections.first.map(paragraph => (
              <Paragraph paragraph={paragraph} key={paragraph.id} textColor={textColor}/>
            ))}
            {nestedSections?.length ? <NestedSections sectionMap={sectionMap} section={section} region={"first"} nestedSections={nestedSections} /> : <></>}
          </div>
          <div className="w-full">
            {sections.second.map(paragraph => (
              <Paragraph paragraph={paragraph} key={paragraph.id} textColor={textColor}/>
            ))}
            {nestedSections?.length ? <NestedSections sectionMap={sectionMap} section={section} region={"second"} nestedSections={nestedSections} /> : <></>}
          </div>
          <div className="w-full">
            {sections.third.map(paragraph => (
              <Paragraph paragraph={paragraph} key={paragraph.id} textColor={textColor}/>
            ))}
            {nestedSections?.length ? <NestedSections sectionMap={sectionMap} section={section} region={"third"} nestedSections={nestedSections} /> : <></>}
          </div>
          <div className="w-full">
            {sections.fourth.map(paragraph => (
              <Paragraph paragraph={paragraph} key={paragraph.id} textColor={textColor}/>
            ))}
            {nestedSections?.length ? <NestedSections sectionMap={sectionMap} section={section} region={"fourth"} nestedSections={nestedSections} /> : <></>}
          </div>
        </div>
      )}
    </div>
  )
}
